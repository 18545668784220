<template>
    <div class="SubscriptionsPortalView ViewWrapper">
        <clv-head-meta :title="$t('Subscriptions Portal')"></clv-head-meta>
        <view-header>
            <h1>{{ $t('Subscriptions Portal') }}</h1>
            <p class="mt-1">{{ $t('subscriptions_portal_subtitle') }}</p>
        </view-header>
        <view-container>
            <div class="row mt-4 mb-4">
                <div class="col-12 col-lg-6">
                    <div class="gk-card">
                        <p class="size-5 weight-4 mb-0">{{ $t('sub_portal_basic_title') }}</p>
                        <p class="weight-4 small text-muted">{{ $t('sub_portal_basic_subtitle') }}</p>
                        <hr/>

                        <p class="mb-0">{{ $t('sub_portal_sub_email_title') }}</p>
                        <p class="small text-muted">
                            <a :href="basicSubscribeEmailUrl" target="_blank">{{ basicSubscribeEmailUrl }}</a>
                        </p>

                        <p class="mb-0">{{ $t('sub_portal_unsub_email_title') }}</p>
                        <p class="small text-muted">
                            <a :href="basicUnsubscribeEmailUrl" target="_blank">{{ basicUnsubscribeEmailUrl }}</a>
                        </p>

                        <p class="mb-0">{{ $t('sub_portal_sub_phone_number_title') }}</p>
                        <p class="small text-muted">
                            <a :href="basicSubscribePhoneNumberUrl" target="_blank">{{ basicSubscribePhoneNumberUrl }}</a>
                        </p>

                        <p class="mb-0">{{ $t('sub_portal_unsub_phone_number_title') }}</p>
                        <p class="small text-muted">
                            <a :href="basicUnsubscribePhoneNumberUrl" target="_blank">{{ basicUnsubscribePhoneNumberUrl }}</a>
                        </p>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="gk-card">
                        <p class="size-5 weight-4 mb-0">{{ $t('sub_portal_one_click_title') }}</p>
                        <p class="weight-4 small text-muted">{{ $t('sub_portal_one_click_subtitle') }}</p>
                        <hr/>

                        <p class="mb-0">{{ $t('sub_portal_sub_email_title') }}</p>
                        <p class="small text-muted">
                            {{ oneClickSubscribeEmailUrl }}
                        </p>

                        <p class="mb-0">{{ $t('sub_portal_unsub_email_title') }}</p>
                        <p class="small text-muted">
                            {{ oneClickUnsubscribeEmailUrl }}
                        </p>

                        <p class="mb-0">{{ $t('sub_portal_sub_phone_number_title') }}</p>
                        <p class="small text-muted">
                            {{ oneClickSubscribePhoneNumberUrl }}
                        </p>

                        <p class="mb-0">{{ $t('sub_portal_unsub_phone_number_title') }}</p>
                        <p class="small text-muted">
                            {{ oneClickUnsubscribePhoneNumberUrl }}
                        </p>

                        <hr/>

                        <p class="mb-0 small">{{ $t('sub_portal_one_click_extra_help_par_1') }}</p>
                        <p class="mb-1 small">{{ $t('sub_portal_one_click_extra_help_par_2') }}</p>
                        <p class="mb-0 small font-italic">
                            <abbr :title="$t('sub_portal_one_click_extra_help_real_example')">
                                {{ $t('Example') }}: {{ oneClickSubscribeEmailUrlWithParams }}
                            </abbr>
                        </p>
                    </div>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-12">
                    <p class="mb-0 weight-5 small">{{ $t('Do you need more information?') }} <a href="#" target="_blank">{{ $t('Visit our Knowledge Base') }}.</a></p>
                </div>
            </div>
        </view-container>
    </div>
</template>

<script>
import { CLOUTLAYER_WEBAPP_SUBSCRIPTIONS_URL } from '@/common/config/config';

/**
 * SubscriptionsPortalToolView : Provides basic information about Subscriptions Portal.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 23 March 2020
 */
export default {
    name: 'SubscriptionsPortalView',
    data () {
        return {
            basicSubscribeEmailUrl: null,
            basicUnsubscribeEmailUrl: null,
            basicSubscribePhoneNumberUrl: null,
            basicUnsubscribePhoneNumberUrl: null,
            oneClickSubscribeEmailUrl: null,
            oneClickSubscribeEmailUrlWithParams: null,
            oneClickUnsubscribeEmailUrl: null,
            oneClickSubscribePhoneNumberUrl: null,
            oneClickUnsubscribePhoneNumberUrl: null
        };
    },
    mounted () {
        // Get necessary information and build Subscriptions Portal URLs for that tenant.
        const tenantAccountId = this.$store.getters['application/tenantAccountId'];
        const subscriptionsPortalWithTenantInPath = CLOUTLAYER_WEBAPP_SUBSCRIPTIONS_URL + '/' + tenantAccountId + '/';

        this.basicSubscribeEmailUrl = subscriptionsPortalWithTenantInPath + 'subscribe/email';
        this.basicUnsubscribeEmailUrl = subscriptionsPortalWithTenantInPath + 'unsubscribe/email';
        this.basicSubscribePhoneNumberUrl = subscriptionsPortalWithTenantInPath + 'subscribe/phone-number';
        this.basicUnsubscribePhoneNumberUrl = subscriptionsPortalWithTenantInPath + 'unsubscribe/phone-number';

        this.oneClickSubscribeEmailUrl = subscriptionsPortalWithTenantInPath + 'one-click/subscribe/email';
        this.oneClickSubscribeEmailUrlWithParams = this.oneClickSubscribeEmailUrl +
            '?eipid={{emailIdentityProfileId}}&ecid={{emailCampaignId}}&type={{communicationSubscriptionType}}&prefLangKey={{emailCampaignLanguageKey}}';
        this.oneClickUnsubscribeEmailUrl = subscriptionsPortalWithTenantInPath + 'one-click/unsubscribe/email';
        this.oneClickSubscribePhoneNumberUrl = subscriptionsPortalWithTenantInPath + 'one-click/subscribe/phone-number';
        this.oneClickUnsubscribePhoneNumberUrl = subscriptionsPortalWithTenantInPath + 'one-click/unsubscribe/phone-number';
    },
    methods: {
    }
};
</script>

<style lang="scss" scoped>
    p, a {
        word-break: break-word;
    }
</style>
